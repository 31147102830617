import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
		<Helmet>
            <title>Solexcursion - Editions passées</title>
            <meta name="description" content="Anciennes éditions de la Solexcursion"/>
        </Helmet>


        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Editions passées</h2>
                    </header>
                    </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>2018</h3>
                            </header>
							<div className="grid-wrapper">
							
							<div className="col-6">
							<h4>Dans la presse</h4>
							<ul className="actions vertical">
                                            <li><a href="https://www.ouest-france.fr/pays-de-la-loire/la-mothe-achard-85150/la-mothe-achard-ils-ont-enfourche-leur-solex-pour-une-drole-de-viree-5817807" target="_blank" className="button fit">Ouest France</a></li>
                                            <li><a href="https://actu.fr/pays-de-la-loire/mothe-achard_85152/220-solex-goguette_17218905.html" target="_blank" className="button fit">Le journal des Sables</a></li>
                                        </ul>
								</div>
							</div>
                            
                          
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>2017</h3>
                            </header>
                            <div className="grid-wrapper">
							
							<div className="col-6">
							<h4>Dans la presse</h4>
							<ul className="actions vertical">
                                            <li><a href="https://www.ouest-france.fr/pays-de-la-loire/les-achards-85150/plus-de-200-solex-en-vadrouille-sur-le-littoral-5059782" target="_blank" className="button fit">Ouest France</a></li>
                                            </ul>
								</div>
							</div>
                        </div>
                    </div>
                </section>
                
            </section>
        </div>

    </Layout>
)

export default Landing
